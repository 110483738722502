<template>
  <div class="info-operation">
    信息化运维
  </div>
</template>

<script>
export default {
  name: 'Infooperation'
}
</script>

<style lang="scss" scoped>
  .info-operation {
    width: 1920px;
    height: 300px;
    line-height: 300px;
    text-align: center;
    background-color: #666;
  }
</style>